import { Capacitor } from "@capacitor/core";
import { hasToken } from "./request";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { ClientLoaderFunctionArgs, redirect } from "@remix-run/react";
import mixpanel, { Dict } from "mixpanel-browser";
import { queryClient } from "./queryClient";
import { OnboardingStatus } from "./types";
import { AFEvent, AppsFlyer } from "appsflyer-capacitor-plugin";
import { Preferences } from "@capacitor/preferences";

export async function checkNotificationPermission({
  request,
}: {
  request: ClientLoaderFunctionArgs["request"];
}) {
  if (!Capacitor.isNativePlatform()) {
    return null;
  }

  if (!(await hasToken())) {
    return null;
  }

  if (await getPersistedValue("notifications_skipped")) {
    return null;
  }

  const { receive } = await FirebaseMessaging.checkPermissions();
  if (receive == "prompt") {
    const nextPath = new URL(request.url).pathname;
    const route = `/notifications?next=${encodeURIComponent(nextPath)}`;
    return redirect(route);
  }

  return null;
}

export async function logout() {
  trackEvent("Logout");
  mixpanel.reset();
  await clearPersistedValue();
  queryClient.clear();
  location.href = "/";
}

export function getNavigateLink(
  userType: "mentor" | "mentee",
  onboardingStatus: OnboardingStatus,
) {
  if (onboardingStatus === "completed" || onboardingStatus === "rejected") {
    const next = localStorage.getItem("next");
    if (next && userType === "mentee") {
      localStorage.removeItem("next");
      return next;
    }

    if (userType === "mentor") {
      return "/dashboard/chat";
    }

    return "/dashboard/find";
  }

  return `/onboarding/${onboardingStatus}`;
}

export function trackEvent(event: string, properties?: Dict) {
  console.debug("[EVENT]", event, properties);
  if (mixpanel.has_opted_in_tracking()) mixpanel.track(event, properties);
  const appsFlyerEventData: AFEvent = {
    eventName: event,
    eventValue: properties,
  };
  if (Capacitor.isNativePlatform()) AppsFlyer.logEvent(appsFlyerEventData);
}

export async function persistValue(key: string, value: string) {
  await Preferences.set({ key, value });
}

export async function getPersistedValue(key: string) {
  const { value } = await Preferences.get({ key });
  return value;
}

export async function removePersistedValue(key: string) {
  await Preferences.remove({ key });
}

export async function clearPersistedValue() {
  await Preferences.clear();
}
