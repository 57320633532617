import { QueryClient } from "@tanstack/react-query";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { getPersistedValue, persistValue, removePersistedValue } from "./utils";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      staleTime: import.meta.env.PROD ? 60 * 1000 : 0,
      refetchOnWindowFocus: "always",
    },
  },
});

export const asyncStoragePersister = createAsyncStoragePersister({
  storage: {
    getItem: async (key: string) => {
      return getPersistedValue(key);
    },
    setItem: async (key: string, value: string) => {
      return persistValue(key, value);
    },
    removeItem: async (key: string) => {
      return removePersistedValue(key);
    },
  },
});
