import {
  Tolgee,
  DevTools,
  LanguageDetector,
  LanguageStorage,
  BackendFetch,
} from "@tolgee/react";
import { FormatIcu } from "@tolgee/format-icu";

export const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatIcu())
  .use(LanguageDetector())
  .use(LanguageStorage())
  .use(BackendFetch())
  .init({
    availableLanguages: ["en", "de"],
    defaultLanguage: "en",
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,
    projectId: 9160,
  });
